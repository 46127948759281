<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-sm-12">
            <b-alert show variant="info" class="default-alert" dismissible>
              {{ $t('emailTemplateInfo') }}
            </b-alert>
            <b-table small :fields="fields" :items="templates" responsive="sm" dark
              class="table-style bootsrap-table table-settings">
              <template #head(org)="">
                {{ $t('organisation') | ucfirst }}
              </template>
              <template #head(type)="">
                {{ $t('type') | ucfirst }}
              </template>
              <template #head(actions)="">
                <span v-if="missingTemplates.length" class="btn btn-sm btn-secondary" @click="showCreateModal()">+
                  {{ $t('add') }}</span>
              </template>
              <template #cell(type)="data">
                {{ $t(data.item.type) }}
              </template>
              <template #cell(enabled)="data">
                <font-awesome-icon v-if="data.item.enabled" icon="check-circle" class="text-success" />
                <font-awesome-icon v-else icon="times" class="text-danger" />
              </template>
              <template #cell(ical)="data">
                <font-awesome-icon v-if="data.item.ical" icon="check-circle" class="text-success" />
                <font-awesome-icon v-else icon="times" class="text-danger" />
              </template>
              <template #cell(actions)="data">
                <div class="nowrap pl-2">
                  <span class="link-edit text-primary" @click="showEditModal(data.item)">
                    <font-awesome-icon icon="edit" />
                    {{ $t('edit') }}
                  </span>
                  <br />
                  <span class="link-remove" @click="showDeleteModal(data.item)">
                    <font-awesome-icon icon="times" />
                    {{ $t('remove') }}
                  </span>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </section>
    <email-template-modal :mode="selectedTemplate.id ? 'edit' : 'create'" :template="selectedTemplate"
      :available-types="missingTemplates" @done="getTemplates" />
    <b-modal id="modal-remove-template" :on-enter-key-press="removeTemplate" hide-footer>
      <template v-slot:modal-title>
        <strong>{{ $t('remove') }}</strong>
      </template>
      <div class="d-block text-center">
        <p>{{ $t('deleteConfirmation') }}</p>
        <b-button variant="danger" class="mt-1 mr-2" @click="$bvModal.hide('modal-remove-template')">
          {{ $t('no') }}
        </b-button>
        <b-button class="mt-1" primary @click="removeTemplate">
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _find from 'lodash/find'
import OkkuApi from '@/services/OkkuApi'
import EmailTemplateModal from '@/components/modals/EmailTemplateModal'
import { SET_ALERT } from '@/store/modules/common/mutationTypes'

const TEMPLATE_TYPES = [
  'reservation-created-by-user',
  'reservation-created-by-other',
  'reservation-created-for-guest',
  'reservation-canceled-by-user',
  'reservation-canceled-by-other',
  'recurring-reservation-created-by-other',
  'google-sync-conflict',
  'azure-sync-conflict',
  'no-show-reminder',
  'no-show-cancelled-by-system',
  'recurring-reservation-created-for-guest',
  'reservation-assets-service',
  'reservation-assets-cancelled'
]

const defaultTemplate = {
  enabled: true,
  subject: '',
  from_address: '',
  from_name: '',
  body: '<h2>Example</h2>'
}

export default {
  name: 'EmailTemplatesPage',
  components: {
    EmailTemplateModal
  },
  data() {
    return {
      fields: [
        { key: 'org' },
        { key: 'type', sortable: true },
        { key: 'from_address', sortable: true },
        { key: 'from_name', sortable: true },
        { key: 'subject', sortable: true },
        { key: 'enabled' },
        { key: 'ical' },
        { key: 'actions', class: 'text-left' }
      ],
      templates: [],
      selectedTemplate: defaultTemplate
    }
  },
  computed: {
    missingTemplates() {
      return TEMPLATE_TYPES.filter(
        type => _find(this.templates, { type }) === undefined
      )
    }
  },
  mounted() {
    this.getTemplates()
  },
  methods: {
    getTemplates() {
      return OkkuApi.getEmailTemplates().then(templates => {
        this.templates = templates
      })
    },
    removeTemplate() {
      return OkkuApi.deleteEmailTemplate(
        this.selectedTemplate.id,
        this.selectedTemplate.type
      )
        .then(() => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('deleteSuccess'),
            variant: 'success'
          })
          this.$bvModal.hide('modal-remove-template')
          this.getTemplates()
        })
        .catch(response => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: response.data ? response.data.message : response,
            variant: 'danger'
          })
        })
    },
    showCreateModal() {
      this.selectedTemplate = { ...defaultTemplate }
      this.$bvModal.show('email-template-modal')
    },
    showDeleteModal(template) {
      this.selectedTemplate = template
      this.$bvModal.show('modal-remove-template')
    },
    showEditModal(template) {
      this.selectedTemplate = template
      this.$bvModal.show('email-template-modal')
    }
  }
}
</script>
<style lang="scss">
.bootsrap-table>.table {
  border-radius: 10px;
  background-color: white;

  thead th {
    vertical-align: middle;
  }
}
</style>
