<template>
  <b-modal
    id="email-template-modal"
    :on-enter-key-press="() => {}"
    hide-footer
    size="xl"
    :title="
      (mode === 'create' ? $t('add') : $t('edit')) +
        ' ' +
        $t('emailTemplate')
    "
    no-enforce-focus
    @hidden="closeModel"
  >
    <div class="d-block text-center">
      <div class="row">
        <div class="col-3 text-left">
          <div class="form-group">
            <label>{{ $t('type') | ucfirst }}:</label>
            <b-form-select
              v-if="!template.id"
              v-model="template.type"
            >
              <option :value="null">
                {{ $t('selectOption') }}
              </option>
              <option
                v-for="type in availableTypes"
                :key="type"
                :value="type"
              >
                {{ $t(type) }}
              </option>
            </b-form-select>
            <input
              v-else
              type="text"
              class="form-control"
              readonly
              :value="$t(template.type)"
            />
            <b-form-invalid-feedback v-if="errors.typeError">{{
              errors.typeError
            }}</b-form-invalid-feedback>
          </div>
        </div>
        <div class="col-3 text-left">
          <div class="form-group">
            <label>{{ $t('fromAddress') }}:</label>
            <input
              v-model="template.from_address"
              type="text"
              class="form-control"
            />
            <b-form-invalid-feedback v-if="errors.addressError">{{
              errors.addressError
            }}</b-form-invalid-feedback>
          </div>
        </div>
        <div class="col-3 text-left">
          <div class="form-group">
            <label>{{ $t('fromName') }}:</label>
            <input
              v-model="template.from_name"
              type="text"
              class="form-control"
            />
            <b-form-invalid-feedback v-if="errors.from_nameError">{{
              errors.from_nameError
            }}</b-form-invalid-feedback>
          </div>
        </div>
        <div class="col-3 text-left">
          <div class="form-group">
            <label>{{ $t('subject') | ucfirst }}:</label>
            <input
              v-model="template.subject"
              type="text"
              class="form-control"
            />
            <b-form-invalid-feedback v-if="errors.subjectError">{{
              errors.subjectError
            }}</b-form-invalid-feedback>
          </div>
        </div>
        <div class="col-12 text-left">
          <div class="form-group">
            <b-form-checkbox
              v-model="template.enabled"
              class="d-inline-block"
              switch
            >
              {{ $t('enabled') | ucfirst }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="template.ical"
              class="d-inline-block ml-2"
              switch
            >
              {{ $t('attachIcalInvitation') }}
            </b-form-checkbox>
          </div>
        </div>
        <div class="col-12 text-left">
          <editor
            id="tinyeditor"
            v-model="template.body"
            :init="{
              height: 500,
              menubar: false,
              branding: false,
              content_css: false,
              skin: false,
              plugins: ['lists image media table code dynamicfields'],
              toolbar: toolbar,
              invalid_elements: 'script'
            }"
            @onInit="editorLoaded"
          />
          <b-form-invalid-feedback v-if="errors.bodyError">{{
            errors.bodyError
          }}</b-form-invalid-feedback>
        </div>
      </div>
      <div class="text-left mt-2 mb-2">
        <div>Click on a dynamic field to add it to the template:</div>
        <b-badge
          v-for="field of dynamicFields"
          :key="field"
          href="#"
          class="mr-1"
          @click="insertContent(`\{\{ ${field} \}\} `)"
        >
          {{ `${field}` }}
        </b-badge>
      </div>
      <div class="text-right">
        <b-button
          variant="danger"
          class="mt-1 mr-2"
          @click="closeModel"
        >
          {{ $t('cancel') }}
        </b-button>
        <b-button class="mt-1" primary @click="upsertEmailTemplate">
          {{ $t('save') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import OkkuApi from '@/services/OkkuApi'
import { SET_ALERT } from '@/store/modules/common/mutationTypes'

import tinymce from 'tinymce/tinymce'
import 'tinymce/plugins/image'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/code'
import 'tinymce/themes/silver'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/icons/default/icons.min'

import Editor from '@tinymce/tinymce-vue'

import { mapGetters } from 'vuex'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'

export default {
  components: {
    'b-modal': BootstrapModalHOC,
    editor: Editor
  },
  props: {
    mode: {
      type: String,
      default: 'create'
    },
    template: {
      type: Object,
      required: true
    },
    availableTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dynamicFields: [
        'reservation.link',
        'reservation.start',
        'reservation.end',
        'organisation.name',
        'floor.name',
        'room.name',
        'desk.name',
        'user.name',
        'user.email',
        'reservation.guests',
        'reservation.subject',
        'reserved.custom.asset',
        'my-reservations',
        'asset.qty',
        'asset.comment'
      ],
      tinyeditor: false,
      errors: {},
      toolbar:
        'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image | removeformat | dynamicfieldsMenu'
    }
  },
  computed: {
    ...mapGetters('common', ['isDashboardAdmin', 'isCustomerAdmin'])
  },
  mounted() {
    // Add option to edit raw HTML and CSS only for Okku Admins
    if (this.isDashboardAdmin) {
      this.toolbar = this.toolbar.concat('| code')
    }
    tinymce.init({})
  },
  methods: {
    editorLoaded(event, editor) {
      this.tinyeditor = editor
    },
    insertContent(value) {
      if ('{{ my-reservations }}'.indexOf(value?.trim()) === 0) {
        this.tinyeditor.insertContent(
          `${window.location.protocol}//${window.location.host}/workplace/my-reservations `
        )
      } else {
        this.tinyeditor.insertContent(value)
      }
    },
    upsertEmailTemplate() {
      if (!this.formValidation(this.template)) {
        return
      }
      OkkuApi.upsertEmailTemplate(this.template)
        .then(response => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('changesSaved'),
            variant: 'success'
          })
          this.$emit('done')

          this.$bvModal.hide('email-template-modal')
          this.errors = ''
        })
        .catch(error => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 5,
            text: error.message,
            variant: 'danger'
          })
        })
    },
    formValidation(template) {
      if (!template.type) {
        this.errors = {
          typeError: 'Type is required'
        }
        return false
      }
      if (!template.from_address) {
        this.errors = {
          addressError: 'Address is required'
        }
        return false
      }
      if (!template.from_name) {
        this.errors = {
          from_nameError: 'Name is required'
        }
        return false
      }
      if (!template.subject) {
        this.errors = {
          subjectError: 'Subject is required'
        }
        return false
      }
      if (!template.body) {
        this.errors = {
          bodyError: 'Email body is required'
        }
        return false
      }

      return true
    },

    closeModel() {
      this.$emit('done')
      this.$bvModal.hide('email-template-modal')
      this.errors = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.invalid-feedback {
  display: block;
}
</style>
